import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Purple Cow Internet Kicks Off a Vibrant Partnership with The Wanderers 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Wanderers-partnership.png"
          alt="Halifax Wanderers Partnership"
          /> 
            <h2>Purple Cow Internet Kicks Off Partnership with The Wanderers</h2>

            <p>We're thrilled to announce that Purple Cow Internet is now a proud sponsor of the <a href="https://hfxwanderersfc.canpl.ca/">Halifax Wanderers</a>, the city's beloved professional soccer team! This partnership marks a significant stride in our continuous effort to support and invest in our community, aligning with local passions and the spirited dynamism of Halifax.</p>

            <h3>Excitement Builds for the Season Opener</h3>

            <p>The Wanderers’ season is kicking off this Saturday, and with forecasts predicting fantastic weather and an attendance of over 5000 soccer fans, the excitement is palpable. What better way to enjoy soccer than with sun, goals, and cheers all around?</p>

            <h3>Introducing the Purple Pasture</h3>

            <p>As part of our partnership, we've introduced an exclusive area at the Wanderers' grounds known as the <strong>Purple Pasture</strong>. This VIP area offers not just the best views of the game but also an array of amenities including refreshments, food, and more, ensuring a premium experience for every guest lucky enough to win access.</p>

            <h4>How to Win Access to the Purple Pasture?</h4>

            <p>It’s simple! As you enter the grounds, keep an eye out for our Purple Cow stations where you can scan a QR code. This quick scan gives you a chance to be instantly upgraded to the Purple Pasture, turning a regular game day into an extraordinary soccer fest.</p>

            <h3>A Summer of Fun Awaits</h3>

            <p>This sponsorship is just the beginning of what we plan to offer this summer. We're setting up more fun interactions, engaging activities, and community-supportive initiatives that will bring our customers, the fans, and the community closer together. </p>

            <h3>Forward Together with the Wanderers</h3>

            <p>Our excitement to partner with the Wanderers reflects our commitment to the Halifax community—supporting local sports is just another way we’re helping to spread the word about Purple Cow’s reliable, customer-centric internet services. We look forward to a long and fruitful partnership with the Wanderers, cheering for every goal and celebrating every victory together.</p>

            <p>Stay tuned for more updates, and here's to a fantastic season ahead. Go Wanderers, and welcome to the herd! 🐮⚽</p>

            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
